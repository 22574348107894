.table-alt-row {
    background: rgb(250, 250, 250);
}
.editable-cell {
    position: relative;
}

.editable-cell-value-wrap {
    padding: 5px 12px;
    cursor: pointer;
}

.editable-row:hover.editable-cell-value-wrap {
    border: 1px solid #d9d9d9;
    border-radius: 4px;
    padding: 4px 11px;
}

.arbitrage .ant-table-tbody > tr > td {
    padding: 3px 3px;
}
td.percent,
td.currency {
  text-align: right;
}
td.totals {
  font-weight: 700;
}
td.stocks {
  text-transform: capitalize;
  font-weight: bold;
  background-color: #6cacdd !important;
}
td.bonds {
  text-transform: capitalize;
  font-weight: bold;
  background-color: #f2903f !important;
}
td.IUL,
td.hybrid {
  text-transform: capitalize;
  font-weight: bold;
  background-color: #cccccc !important;
}
td.annuities {
  text-transform: capitalize;
  font-weight: bold;
  background-color: #ebb510 !important;
}
td.cash {
  text-transform: capitalize;
  font-weight: bold;
  background-color: #3e6ec4 !important;
}
input.text {
  font-size: 12px;
  line-height: 12px;
  padding: 0 4px !important;
  border: None;
  border-bottom: #cccccc solid 2px;
  box-shadow: None;
}
input:focus {
  border-color: None !important;
  border: None !important;
  outline: None !important;
  background-color: #fdfbbf;
}
button:focus {
  border-color: None !important;
  border: None !important;
  outline: None !important;
}
.input-group.cashflow {
  width: 104px;
}
input.required {
  border-color: #343434;
}
input.numberinput {
  padding-top: 0;
  text-align: right;
}
input.number {
  font-size: 12px;
  line-height: 12px;
  padding: 0 4px !important;
  border: None;
  border-bottom: #cccccc solid 2px;
  box-shadow: None;
  text-align: right;
}
input.error {
  border: None;
  border-bottom: #ff1600 solid 2px;
  background-color: #eecccc;
  box-shadow: None;
}
input.percententry {
  text-align: right;
  width: 50px;
  height: 30px;
  margin-left: 0;
}
input.invested {
  width: 100px;
  text-align: right;
  margin-right: 20px;
  margin-left: 10px;
}
input.submit-btn {
  padding: 4px 20px;
  font-size: 14px;
  border-radius: 4px;
  margin: 0 0 4px 8px;
}
tr:nth-child(odd) > td.positive {
  background-color: #d9f5c8 !important;
}
tr:nth-child(odd) > td.negative {
  background-color: #ffd5d0 !important;
}
tr:nth-child(even) > td.positive {
  background-color: #d9f5c8 !important;
}
tr:nth-child(even) > td.negative {
  background-color: #ffd5d0 !important;
}

.dropzone {
  text-align: center;
  vertical-align: middle;
  font-size: 2.5em;
  padding-top: 65px;
  margin: 10px 0;
  width: 100%;
  height: 200px;
  border: dashed 2px black;
  background-color: #eee;
}

.dropzone-active {
  background-color: lightyellow;
}

.dropzone-accept {
  background-color: lightgreen;
}

.dropzone-reject {
  background-color: lightsalmon;
}

.table-condensed .ant-table-thead > tr > th, 
.table-condensed .ant-table-tbody > tr > td {
    padding: 2px 2px !important;
}
.boxed-label {
  text-align: center;
  margin-bottom: 0;
}
.boxed-currency {
  border: 0.1rem solid #414f56;
  text-align: center;
  padding-top: 1rem;
  margin: 0 10%;
  min-height: 4rem;
}

.table-td-blue {
  background-color: #213863;
  color: #fff;
  border-left: 0.1rem solid #fff;
}
.table-td-red {
  background-color: #ee3924;
  color: #fff;
  border-left: 0.1rem solid #fff;
}
.table-td-red-text {
  color: #ee3924;
}
.table-th-blue {
  background-color: #213863;
  color: #fff;
  border-left: 0.1rem solid #fff;
}
.table-th-lt-gray {
  background-color: #adadad;
  color: #fff;
  border-left: 0.1rem solid #fff;
}
.table-th-dk-gray {
  background-color: #707070;
  color: #fff;
  border-left: 0.1rem solid #fff;
}

.nav-tabs > li {
  font-size: 1.2rem;
  margin-top: 0.4rem;
}
.nav-tabs > li > a {
  background-color: #e2e5e7;
  border-radius: 3.2rem 3.2rem 0 0;
}

th {
  text-align: center;
}

/***************************
* Buckets                  *
***************************/

.side-scroller {
    display: flex;
    width: auto;
    overflow-x: scroll;
    overflow-y: hidden;
    white-space: nowrap;
}

.bucket-top-menu {
    height: 30px;
    background-color: #f5f5f5;
    color: #999;
    text-align: center;
    margin-bottom: 10px;
    line-height: 30px;
}
.bucket-name {
    font-weight: 700;
    text-align: left;
    border-bottom: #343434 solid 2px;
    margin: 0;
    margin-bottom: 2px;
    height: 22px;
    width: 100px;
}
.bucket-name-text {
    text-align: left;
}
.bucket-delete {
    color: #cccccc;
    float: right;
    margin-right: 3px;
    cursor: pointer;
    width: 12.85px;
    z-index: 100;
}
.bucket-delete:hover {
    color: rgba(255, 0, 0, 0.51);
}
.bucket-labels {
    width: 130px;
    float: left;
    padding-top: 26px;
    margin-right: 4px;
}
.bucket-label {
    line-height: 20px;
    font-size: 13px;
    margin: 0 0 10px;
}
.bucket-values {
    width: 110px;
    margin-right: 18px;
    margin-left: 4px;
    display: inline-block;
    height: 300px;
}
.bucket-values-add {
    display: inline-block;
    color: #ccc;
    margin: auto;
}
.bucket-add-segment-button {
    width: 100px;
    height: 290px;
    background-color: white;
    border: none;
}

.bucket-add-segment-button:hover {
    cursor: pointer;
    color: rgb(69, 121, 99);
}

.bucket-enter {
    opacity: 0.01;
}
.bucket-enter.bucket-enter-active {
    opacity: 1;
    transition: opacity 0.5s ease-in;
}
.bucket-leave {
    opacity: 1;
}
.bucket-leave.bucket-leave-active {
    opacity: 0.01;
    transition: opacity 0.5s ease-in;
}

body,
html {
    font-family: 'Lato', sans-serif;
}

@font-face {
    font-family: 'Lato';
    src: local('Lato'),
        url(/public/static/font/Lato-Regular.ttf) format('truetype');
}

/* .example {
    margin: 12px;
} */

/* Force update ant style */
/* .ant-input {
    padding: 16px 12px 4px 11px;
} */

/* .ant-select .ant-select-selector {
    padding: 16px 10px 4px 11px;
}

.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    padding: 16px 10px 4px 11px;
    height: 48px;
}

.ant-select-single .ant-select-selector .ant-select-selection-search {
    top: 16px;
} */

/* 
    * START - Custom CSS for input with floating outlined label
*/
.container {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.material-textfield {
    position: relative;
}

label {
    color: gray;
}

.material-textfield label {
    position: absolute;
    /* font-size: 16px; */
    left: 0;
    top: 50%;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
    background-color: white;
    color: gray;
    padding: 0 0.3rem;
    margin: 0 0.5rem;
    transition: 0.1s ease-out;
    -webkit-transform-origin: left top;
            transform-origin: left top;
    pointer-events: none;
}

.material-textfield-input {
    /* font-size: 1rem; */
    outline: none;
    border: 1px solid gray;
    border-radius: 5px;
    padding: 1rem 0.7rem;
    color: gray;
    transition: 0.1s ease-out;

    width: 100%;
}

.material-textfield-input:focus {
    border-color: #00bac9;
}

.material-textfield-input:focus + label {
    color: #00bac9;
    top: 0;
    -webkit-transform: translateY(-50%) scale(0.9) !important;
            transform: translateY(-50%) scale(0.9) !important;
}

.material-textfield-input:not(:placeholder-shown) + label {
    top: 0;
    -webkit-transform: translateY(-50%) scale(0.9) !important;
            transform: translateY(-50%) scale(0.9) !important;
}

.material-textfield-input:not(:focus)::-webkit-input-placeholder {
    opacity: 0;
}

.material-textfield-input:not(:focus)::placeholder {
    opacity: 0;
}

/* 
    * END - Custom CSS for input with floating outlined label
*/

.profile-form-error {
    color: red;
}

.primary-color {
    color: #00bac9;
}

.primary-border-color {
    border-color: #00bac9;
    color: #00bac9;
}

