body,
html {
    font-family: 'Lato', sans-serif;
}

@font-face {
    font-family: 'Lato';
    src: local('Lato'),
        url(/public/static/font/Lato-Regular.ttf) format('truetype');
}

/* .example {
    margin: 12px;
} */

/* Force update ant style */
/* .ant-input {
    padding: 16px 12px 4px 11px;
} */

/* .ant-select .ant-select-selector {
    padding: 16px 10px 4px 11px;
}

.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    padding: 16px 10px 4px 11px;
    height: 48px;
}

.ant-select-single .ant-select-selector .ant-select-selection-search {
    top: 16px;
} */

/* 
    * START - Custom CSS for input with floating outlined label
*/
.container {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.material-textfield {
    position: relative;
}

label {
    color: gray;
}

.material-textfield label {
    position: absolute;
    /* font-size: 16px; */
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    background-color: white;
    color: gray;
    padding: 0 0.3rem;
    margin: 0 0.5rem;
    transition: 0.1s ease-out;
    transform-origin: left top;
    pointer-events: none;
}

.material-textfield-input {
    /* font-size: 1rem; */
    outline: none;
    border: 1px solid gray;
    border-radius: 5px;
    padding: 1rem 0.7rem;
    color: gray;
    transition: 0.1s ease-out;

    width: 100%;
}

.material-textfield-input:focus {
    border-color: #00bac9;
}

.material-textfield-input:focus + label {
    color: #00bac9;
    top: 0;
    transform: translateY(-50%) scale(0.9) !important;
}

.material-textfield-input:not(:placeholder-shown) + label {
    top: 0;
    transform: translateY(-50%) scale(0.9) !important;
}

.material-textfield-input:not(:focus)::placeholder {
    opacity: 0;
}

/* 
    * END - Custom CSS for input with floating outlined label
*/

.profile-form-error {
    color: red;
}

.primary-color {
    color: #00bac9;
}

.primary-border-color {
    border-color: #00bac9;
    color: #00bac9;
}
